
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$skitterbytes-ui-primary: mat-palette($mat-grey, 800, 200, 900);
$skitterbytes-ui-accent: mat-palette($mat-purple, A200, A100, A400);

// The warn palette is optional (defaults to red).
$skitterbytes-ui-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$skitterbytes-ui-theme: mat-light-theme((
  color: (
    primary: $skitterbytes-ui-primary,
    accent: $skitterbytes-ui-accent,
    warn: $skitterbytes-ui-warn
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($skitterbytes-ui-theme);

$skitterbytes-ui-typography: mat-typography-config(
  $font-family: 'BebasNeue, Roboto'
);

@include angular-material-typography($skitterbytes-ui-typography);

@include mat-base-typography($skitterbytes-ui-typography);

/* You can add global styles to this file, and also import other style files */
h1 {
    color: black;
    font-size: 250%;
  }

html, body { height: 100%; }
body { margin: 0; font-family: BebasNeue, Roboto; background-color: whitesmoke; }